import React from "react";
/* import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store"; */

function App() {
  return (
    <iframe
      title="Donations Page" 
      src={process.env.REACT_APP_WORDPRESS_DONATIONS_PAGE}
      style={{ border: 'none', width: '100vw', height: '100vh' }}   
    />
  );
  /* return (
    <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    </Provider>
  ); */
}

export default App;
